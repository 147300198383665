import { Modal } from 'antd';

/**
 * `deleteFileItem` is a function that deletes a file item from the files list and files configuration.
 *
 * @function
 * @param {Object} fileInfos - Object containing file information.
 * @param {Array} filesList - The current list of files.
 * @param {Function} setFilesList - Function to set the list of files.
 * @param {Array} filesConfiguration - The current files configuration.
 * @param {Function} setFilesConfiguration - Function to set files configuration.
 * @param {Function} deleteFile - Function to delete a file.
 * @returns {void} No return value.

 */
const deleteFileItem = async (
  fileInfos,
  filesList,
  setFilesList,
  filesConfiguration,
  setFilesConfiguration,
  deleteFile
) => {
  const { name, id } = fileInfos;
  if (id) {
    await deleteFile(id);
  }

  const updatedFilesList = filesList.filter((file) => file.name !== name);
  setFilesList(updatedFilesList);

  const updatedFileIndexAndTypeList = filesConfiguration.filter(
    (item) => item.name !== name
  );
  setFilesConfiguration(updatedFileIndexAndTypeList);
};

/**
 * `handleFileDelete` is a function that opens a confirmation modal for file deletion and handles the deletion process.
 *
 * @function
 * @param {Object} fileInfos - Object containing file information.
 * @param {Function} deleteFile - Function to delete a file.
 * @param {Array} filesList - The current list of files.
 * @param {Function} setFilesList - Function to set the list of files.
 * @param {Array} filesConfiguration - The current files configuration.
 * @param {Function} setFilesConfiguration - Function to set files configuration.
 * @param {Function} t - Function for translation.
 * @returns {Object} A reference to the modal which can be used to programmatically close the modal.

 */
export const handleFileDelete = (
  fileInfos,
  deleteFile,
  filesList,
  setFilesList,
  filesConfiguration,
  setFilesConfiguration,
  t
) =>
  Modal.confirm({
    title: t('files.modal.delete_title'),
    content: t('files.modal.delete_content'),
    okText: t('files.modal.yes'),
    okType: 'danger',
    cancelText: t('files.modal.no'),
    onOk: async () => {
      await deleteFileItem(
        fileInfos,
        filesList,
        setFilesList,
        filesConfiguration,
        setFilesConfiguration,
        deleteFile
      );
    }
  });
