import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom select component for choosing values from a list of enums.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key used to fetch the list of enums.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const SelectEnums = ({ dbKey }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/civil-liabilities/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <Item name={[`${dbKey}`]}>
      <Select>
        {(enums?.[dbKey] || []).map((v) => (
          <Option key={v} value={v}>
            {dbKey !== 'APE_code' ? t(`civil-liability.form.enums.${v}`) : v}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectEnums.propTypes = {
  dbKey: PropTypes.string.isRequired
};
