import { Route, Routes } from 'react-router-dom';
import { ListAutomissions } from './ListAutomissions';
import { AutomissionsCreateUpdate } from './AutomissionsCreateUpdate';
import { AutomissionsShow } from './AutomissionsShow';
import { Exception } from '../../../../components';

export const AutomissionsRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<AutomissionsCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<AutomissionsCreateUpdate purpose="create" />}
    />
    <Route path="show/:id" element={<AutomissionsShow />} />
    <Route index element={<ListAutomissions />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
