import { Route, Routes } from 'react-router-dom';
import { ListAdValorem } from './ListAdValorem';
import { AdValoremCreateUpdate } from './AdValoremCreateUpdate';
import { AdValoremShow } from './AdValoremShow';
import { Exception } from '../../../../components';

/**
 * `AdValoremRouter` is a component responsible for defining the routing structure for Ad Valorem related pages.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the Ad Valorem router.
 */

export const AdValoremRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<AdValoremCreateUpdate purpose="create" />}
    />
    <Route path="edit/:id" element={<AdValoremCreateUpdate purpose="edit" />} />
    <Route path="show/:id" element={<AdValoremShow />} />
    <Route index element={<ListAdValorem />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
