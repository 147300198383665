import PropTypes from 'prop-types';
import { Modal, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const { Paragraph, Text } = Typography;

/**
 * A reusable modal component for claim closure confirmation.
 *
 * @function
 * @param {boolean} open - Determines whether the modal is open or closed.
 * @param {Function} onCancel - Callback function to handle modal cancellation.
 * @param {Function} onOk - Callback function to handle modal confirmation.
 * @param {string} claimReferenceNumber - The claim reference number to be displayed in the modal.
 * @returns {JSX.Element} The JSX element representing the closure confirmation modal.
 */

export const ModalClosure = ({ open, onOk, claimReferenceNumber }) => {
  const { t } = useTranslation();
  return (
    <Modal
      footer={[
        <Button onClick={() => onOk()}>{t('claims.form.ok_button')}</Button>
      ]}
      open={open}
      title={t('claims.form.closure_validation')}
    >
      <Typography>
        <Paragraph>
          <Text>{t('claims.form.claim_number')}</Text>
          <Text strong>{`${claimReferenceNumber}`}</Text>
        </Paragraph>
        <Paragraph>
          {t('claims.form.closure_mail_sent', { claimReferenceNumber })}
        </Paragraph>
      </Typography>
    </Modal>
  );
};

ModalClosure.propTypes = {
  open: PropTypes.bool,
  onOk: PropTypes.func,
  claimReferenceNumber: PropTypes.string
};

ModalClosure.defaultProps = {
  open: false,
  onOk: () => {},
  claimReferenceNumber: null
};
