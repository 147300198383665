import { Route, Routes } from 'react-router-dom';
import { ListOtherVehicles } from './ListOtherVehicles';
import { OtherVehiclesCreateUpdate } from './OtherVehiclesCreateUpdate';
import { OtherVehicleShow } from './OtherVehicleShow';
import { Exception } from '../../../../components';
import { OtherVehiclesContextProvider } from './OtherVehiclesContext';

export const OtherVehiclesRouter = () => (
  <OtherVehiclesContextProvider>
    <Routes>
      <Route
        path="create"
        element={<OtherVehiclesCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<OtherVehiclesCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<OtherVehicleShow />} />
      <Route index element={<ListOtherVehicles />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </OtherVehiclesContextProvider>
);
