import { Route, Routes } from 'react-router-dom';
import { ListGolf } from './ListGolf';
import { GolfCreateUpdate } from './GolfCreateUpdate';
import { GolfShow } from './GolfShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing golf claims, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to golf claims.
 */
export const GolfRouter = () => (
  <Routes>
    <Route path="create/:id" element={<GolfCreateUpdate purpose="create" />} />
    <Route path="edit/:id" element={<GolfCreateUpdate purpose="edit" />} />
    <Route path="show/:id" element={<GolfShow />} />
    <Route index element={<ListGolf />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
