import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Flex, Image, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { createFileFromJSON } from '../../components/CreateUpdateContainerV3/utils/createFileFromJSON';

/**
 * A component for displaying photos of a Claim.
 *
 * This component fetches and displays photos of the Claim
 * based on the provided base URL.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.baseUrl - The base URL for fetching photos.
 *
 * @returns {JSX.Element} The rendered `PhotosClaim` component.
 */

export const DocumentsFieldDisplay = ({
  baseUrl,
  fieldName,
  idWitoutParams,
  title
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState([]);

  const getDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${baseUrl}/${idWitoutParams || id}?populate=documents.file`
      });
      if (data.documents !== null) {
        const picturesClaim = data.documents.filter((doc) =>
          doc.fileKey.includes(`${fieldName}`)
        );

        const docsFiles = await Promise.all(
          picturesClaim.map((doc) =>
            createFileFromJSON(doc, dispatchAPI, message)
          )
        );

        if (docsFiles.every((file) => file && file.url)) {
          setDocs(docsFiles);
        } else {
          message(t('errors.message.photos_not_found'));
        }
      }
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  }, [baseUrl, id, dispatchAPI, message]);

  useEffect(() => {
    (async () => {
      await getDocuments();
    })();
  }, []);

  return (
    <Card title={t(`${title}`)} className="photo-card">
      <Spin spinning={isLoading} size="large" />
      {docs.length > 0 && (
        <Flex gap="small" wrap="wrap">
          {docs.map((picture) => (
            <Image
              key={picture._id}
              width={100}
              height={100}
              src={picture.url}
            />
          ))}
        </Flex>
      )}
    </Card>
  );
};

DocumentsFieldDisplay.propTypes = {
  baseUrl: PropTypes.string,
  fieldName: PropTypes.string,
  idWitoutParams: PropTypes.string,
  title: PropTypes.string
};

DocumentsFieldDisplay.defaultProps = {
  baseUrl: null,
  idWitoutParams: null,
  fieldName: null,
  title: null
};
