import { Timeline } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handleStatusUtils } from './handleStatusUtils';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { SubscriptionContext } from '../SubscriptionContext';

/**
 * Component for displaying the history of a subscription.
 * @component
 * @returns {JSX.Element} The JSX element for displaying the subscription history.
 */
export const History = () => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { current } = handleStatusUtils();
  const { message } = useErrorMessage();
  const [history, setHistory] = useState([]);
  const [historyTimeline, setHistoryTimeline] = useState([]);
  const { forceRefresh } = useContext(SubscriptionContext);

  const getHistory = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subscriptions/${id}?populate=customer_manager,unit_manager`
      });
      setHistory(data.history);
    } catch (e) {
      message(e);
    }
  };

  const buildTimeline = () =>
    history?.map((entry) => ({
      children: entry
    }));

  useEffect(() => {
    const dataTimeline = buildTimeline();
    setHistoryTimeline(dataTimeline);
  }, [history]);

  useEffect(() => {
    (async () => {
      await getHistory();
    })();
  }, [current, forceRefresh]);

  return history ? <Timeline mode="alternate" items={historyTimeline} /> : null;
};
