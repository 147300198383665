import { Route, Routes } from 'react-router-dom';
import { ListCivilLiabilityPro } from './ListCivilLiabilityPro';
import { CivilLiabilityProCreateUpdate } from './CivilLiabilityProCreateUpdate';
import { CivilLiabilityProShow } from './CivilLiabilityProShow';
import { Exception } from '../../../../components';

/**
 * `CivilLiabilityProRouter` is a component responsible for defining the routing structure.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the Ad Valorem router.
 */

export const CivilLiabilityProRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<CivilLiabilityProCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<CivilLiabilityProCreateUpdate purpose="create" />}
    />
    <Route path="show/:id" element={<CivilLiabilityProShow />} />
    <Route index element={<ListCivilLiabilityPro />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
