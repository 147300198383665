import React, { useState, createContext, useEffect } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

export const DocumentsContext = createContext();

/**
 * `DocumentsContextProvider` is a React context provider that encapsulates document-related data and state management logic.
 *
 * The context provides several values:
 * - `enums`: An array containing enumeration values related to contracts.
 * - `contractId`: The currently selected contract's ID.
 * - `forceRefresh`: A boolean indicating whether a refresh is triggered.
 * - `contracts`: An array of available contracts.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {React.Node} props.children The child components to be rendered inside the provider.
 * @returns {React.ElementType} A context provider wrapping its children.
 */

export const DocumentsContextProvider = ({ children }) => {
  const [enums, setEnums] = useState([]);
  const [contractId, setContractId] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [contracts, setContracts] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <DocumentsContext.Provider
      value={{
        enums,
        forceRefresh,
        setForceRefresh,
        contracts,
        setContracts,
        contractId,
        setContractId
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
