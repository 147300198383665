import { Route, Routes } from 'react-router-dom';
import { ListGolf } from './ListGolf';
import { GolfCreateUpdate } from './GolfCreateUpdate';
import { GolfShow } from './GolfShow';
import { Exception } from '../../../components';
import { GolfContextProvider } from './GolfContext';

export const GolfRouter = () => (
  <GolfContextProvider>
    <Routes>
      <Route path="create" element={<GolfCreateUpdate purpose="create" />} />
      <Route path="edit/:id" element={<GolfCreateUpdate purpose="edit" />} />
      <Route path="show/:id" element={<GolfShow />} />
      <Route index element={<ListGolf />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </GolfContextProvider>
);
