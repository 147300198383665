import { Route, Routes } from 'react-router-dom';
import { ListAutomissions } from './ListAutomissions';
import { AutomissionsCreateUpdate } from './AutomissionsCreateUpdate';
import { AutomissionShow } from './AutomissionShow';
import { Exception } from '../../../../components';
import { AutomissionsContextProvider } from './AutomissionsContext';

export const AutomissionsRouter = () => (
  <AutomissionsContextProvider>
    <Routes>
      <Route
        path="create"
        element={<AutomissionsCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<AutomissionsCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<AutomissionShow />} />
      <Route index element={<ListAutomissions />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </AutomissionsContextProvider>
);
