// Main nav menuItems
export const routes = {
  HOME: '/',
  PROGRAMMES: '/programmes',
  CLAIMS: '/claims',
  SUBSCRIPTIONS: '/subscriptions',
  DOCUMENTS: '/documents',
  CONTACTS: '/contacts',
  CONFIGURATIONS: '/configurations',
  USERS: '/users',
  COMPANIES: '/companies'
  // TEMPLATE_DOCS: '/template-docs',
  // WORKFLOWS: '/workflows',
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  LEGAL: '/legal-notice'
};

// url search parameters
export const pathSearches = {};
