import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { SelectUnitStaff } from './SelectUnitStaff';

const { Option } = Select;

export const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);

  // TODO: Add the following fields to the form: contract number, entity

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['creation_date'],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['siren'],
      rules: [{ required: true }]
    },
    {
      name: ['legal_status'],
      required: true,
      input: (
        <Select loading={isFieldsLoading} showSearch>
          {(enums || []).map((status) => (
            <Option key={status.libelle} value={status.libelle}>
              {status.libelle}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['number'],
      rules: [{ required: true }]
    },
    {
      name: ['additional']
    },
    {
      name: ['street'],
      rules: [{ required: true }]
    },
    {
      name: ['zip_code'],
      rules: [{ required: true }]
    },
    {
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      name: ['unit_manager'],
      rules: [{ required: true }],
      input: <SelectUnitStaff dbKey="unit_manager" />
    },
    {
      name: ['unit_support'],
      input: <SelectUnitStaff dbKey="unit_support" />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
