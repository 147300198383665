import { Badge, Button, Dropdown, Menu } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { handleNotifMenuItemLink } from './handleNotificationMenuLink';

export const Notifications = ({
  notifications,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const deleteNotification = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `notifications/delete/${id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const menu = (
    <Menu>
      {(notifications || []).map((notification) => (
        <Menu.Item>
          {handleNotifMenuItemLink(
            notification?._id,
            notification?.item,
            deleteNotification,
            t
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  return notifications?.length ? (
    <Dropdown trigger={['hover']} overlay={menu}>
      <Button type="link" style={{ margin: 0 }}>
        <Badge count={notifications?.length} status="warning" size="small">
          <BellOutlined
            style={{
              fontSize: 24
            }}
          />
        </Badge>
      </Button>
    </Dropdown>
  ) : null;
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      item: PropTypes.shape({
        type: PropTypes.shape({
          _id: PropTypes.string
        })
      })
    })
  ),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

Notifications.defaultProps = {
  notifications: null,
  forceRefresh: false,
  setForceRefresh: null
};
