import PropTypes from 'prop-types';
import { Layout } from 'antd';

const { Content } = Layout;

export const ContentCustom = ({ children, style }) => (
  <Content
    style={{
      padding: '16px 24px',
      background: 'var(--bodyBackground)',
      minHeight: 'fit-content',
      ...style
    }}
  >
    {children}
  </Content>
);

ContentCustom.propTypes = {
  style: PropTypes.shape({})
};

ContentCustom.defaultProps = {
  style: undefined
};
