import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Flex, Image, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { createFileFromJSON } from '../../../components/CreateUpdateContainerV3/utils/createFileFromJSON';

/**
 * A component for displaying photos of a Claim.
 *
 * This component fetches and displays photos of the Claim
 * based on the provided base URL.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.baseUrl - The base URL for fetching photos.
 *
 * @returns {JSX.Element} The rendered `PhotosClaim` component.
 */

export const PhotosClaims = ({ baseUrl }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [photosClaim, setPhotosClaim] = useState([]);

  const getPhotos = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${baseUrl}/${id}?populate=documents.file`
      });
      if (data.documents !== null) {
        const picturesClaim = data.documents.filter((doc) =>
          doc.fileKey.includes('pictures_damages_caused')
        );

        const ClaimFiles = await Promise.all(
          picturesClaim.map((doc) =>
            createFileFromJSON(doc, dispatchAPI, message)
          )
        );

        if (ClaimFiles.every((file) => file && file.url)) {
          setPhotosClaim(ClaimFiles);
        } else {
          message(t('errors.message.photos_not_found'));
        }
      }
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  }, [baseUrl, id, dispatchAPI, message]);

  useEffect(() => {
    (async () => {
      await getPhotos();
    })();
  }, []);

  return (
    <Card title={t('claims.form.photos_of_the_Claim')} className="photo-card">
      <Spin spinning={isLoading} size="large" />
      {photosClaim.length > 0 && (
        <Flex gap="small" wrap="wrap">
          {photosClaim.map((picture) => (
            <Image
              key={picture._id}
              width={100}
              height={100}
              src={picture.url}
            />
          ))}
        </Flex>
      )}
    </Card>
  );
};

PhotosClaims.propTypes = {
  baseUrl: PropTypes.string
};

PhotosClaims.defaultProps = {
  baseUrl: undefined
};
