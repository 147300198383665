import { useTranslation } from 'react-i18next';
import { ButtonToDownload } from './ButtonToDownload';

/**
 * Custom hook to generate columns configuration for a table.
 *
 * @hook
 *
 * @returns {Array} An array of column configuration objects.
 */

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('entities.form.entity_name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('entities.form.kbis'),
      key: 'kbis',
      render: (record) => (
        <ButtonToDownload record={record} type="kbis_entitiy" />
      )
    },
    {
      title: t('entities.form.ids'),
      key: 'ids',
      render: (record) => (
        <ButtonToDownload record={record} type="ics_entitiy" />
      )
    }
  ];
};
