import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MailOutlined } from '@ant-design/icons';
import { Button, Modal, Collapse } from 'antd';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { CreateUpdateExpert } from '../../Experts/CreateUpdateExpert';
import { useActionColumn } from '../../Experts/actionColumn';
import { useColumns } from '../../Experts/columns';

const { Panel } = Collapse;

export const ExpertCall = ({ company, programmeType, claimId, url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [idWithoutParams, setIdWithoutParams] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const actionColumn = useActionColumn(
    () => {
      setActiveKey('2');
    },
    setIdWithoutParams,
    idWithoutParams,
    claimId,
    url
  );
  const columns = useColumns();
  const { t } = useTranslation();

  return (
    <>
      <Button icon={<MailOutlined />} onClick={() => setIsModalOpen(true)}>
        {`${t('claims.form.expert_call')} `}
      </Button>
      <Modal
        onCancel={() => closeModal()}
        open={isModalOpen}
        footer={[
          <Button onClick={() => closeModal()}>
            {t('claims.form.ok_button')}
          </Button>
        ]}
        title={`${t('claims.form.expert_call')} `}
        centered
        width={1200}
      >
        {company && programmeType && (
          <Collapse accordion activeKey={activeKey} onChange={setActiveKey}>
            <Panel header={t('claims.experts.contact')} key="1">
              <ListResource
                extraQuery={`company=${company}&programme_types=${programmeType}`}
                resource="experts"
                resourceName="experts"
                columns={[...columns, ...actionColumn]}
                withUploadButton={false}
                withPageHeader={false}
                noCreateButton
                showAction={false}
                duplicateAction={false}
                printAction={false}
                editAction={false}
                onDoubleClickAction={false}
              />
            </Panel>
            <Panel
              header={t('claims.experts.edit_expert')}
              key="2"
              hidden={!idWithoutParams}
            >
              <CreateUpdateExpert
                purpose="edit"
                idWithoutParams={idWithoutParams}
                setIdWithoutParams={setIdWithoutParams}
              />
            </Panel>
            <Panel header={t('claims.experts.create_expert')} key="3">
              <CreateUpdateExpert
                purpose="create"
                programmeType={programmeType}
              />
            </Panel>
          </Collapse>
        )}
      </Modal>
    </>
  );
};

ExpertCall.propTypes = {
  company: PropTypes.string,
  programmeType: PropTypes.string,
  claimId: PropTypes.string,
  url: PropTypes.string.isRequired
};

ExpertCall.defaultProps = {
  company: null,
  programmeType: null,
  claimId: null
};
