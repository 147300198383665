import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card, Flex, Typography } from 'antd';

/**
 * Drivers Component.
 * Displays details of both principal and secondary drivers.
 *
 * @component
 *
 * @param {Object} props - Component properties.
 * @param {Object|null} props.principal_driver - Principal driver details.
 * @param {string} props.principal_driver.first_name - First name of the principal driver.
 * @param {string} props.principal_driver.last_name - Last name of the principal driver.
 * @param {number} props.principal_driver.coefficient - Coefficient of the principal driver.
 * @param {Object[]} props.principal_driver.driving_licences - List of driving licences of the principal driver.
 * @param {string} props.principal_driver.driving_licences.licence_selection - Licence type/selection.
 * @param {string} props.principal_driver.driving_licences.date_obtained - Date when the licence was obtained.
 *
 * @param {Object[]|null} props.secondary_drivers - List of secondary drivers' details.
 * @param {string} props.secondary_drivers.first_name - First name of the secondary driver.
 * @param {string} props.secondary_drivers.last_name - Last name of the secondary driver.
 * @param {number} props.secondary_drivers.coefficient - Coefficient of the secondary driver.
 * @param {Object[]} props.secondary_drivers.driving_licences - List of driving licences of the secondary driver.
 * @param {string} props.secondary_drivers.driving_licences.licence_selection - Licence type/selection.
 * @param {string} props.secondary_drivers.driving_licences.date_obtained - Date when the licence was obtained.
 *
 */

export const Drivers = ({ principal_driver, secondary_drivers }) => {
  const { t } = useTranslation();

  const renderDriverDetails = (driver) => (
    <>
      <Flex className="driver-name">
        {driver.first_name} {driver.last_name.toUpperCase()}
      </Flex>
      <Flex>
        {t('drivers.form.coefficient')}: {driver.coefficient}
      </Flex>
      <Flex>{t('drivers.form.driving_licences')}:</Flex>
      <ul>
        {driver?.driving_licences.map((driving_licence) => (
          <li key={driving_licence.licence_selection}>
            <Flex justify="space-between" gap="middle">
              <span>
                {t('drivers.form.driving_licences')}{' '}
                {driving_licence.licence_selection}
              </span>{' '}
              {driving_licence.date_obtained &&
                moment(driving_licence.date_obtained).format('DD/MM/YYYY')}
            </Flex>
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <Card className="driver-wrapper-card">
      <Typography.Title level={5}>
        {t('vehicles.form.drivers')}
      </Typography.Title>
      <Flex gap="small" wrap="wrap">
        {principal_driver ? (
          <Card
            className="driver-card main"
            extra={t('drivers.form.is_principal')}
          >
            {renderDriverDetails(principal_driver)}
          </Card>
        ) : null}
        {secondary_drivers
          ? secondary_drivers.map((secondary_driver) => (
              <Card
                key={secondary_driver._id}
                extra={t('drivers.form.other_driver')}
                className="driver-card"
              >
                {renderDriverDetails(secondary_driver)}
              </Card>
            ))
          : null}
      </Flex>
    </Card>
  );
};

Drivers.propTypes = {
  principal_driver: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    coefficient: PropTypes.number,
    driving_licences: PropTypes.arrayOf(
      PropTypes.shape({
        licence_selection: PropTypes.string,
        date_obtained: PropTypes.string
      })
    )
  }),
  secondary_drivers: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      coefficient: PropTypes.number,
      driving_licences: PropTypes.arrayOf(
        PropTypes.shape({
          licence_selection: PropTypes.string,
          date_obtained: PropTypes.string
        })
      )
    })
  )
};

Drivers.defaultProps = {
  principal_driver: null,
  secondary_drivers: null
};
