import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useAuthContext } from '../../contexts/AuthContext';
import { SelectByRole } from './SelectByRole';
import { headers } from './headers';

/**
 * `ListUsers` is a component for listing and managing users.
 *
 * @component
 * @returns {JSX.Element} The JSX element for listing and managing users.
 */

export const ListUsers = () => {
  const [loaded, setLoaded] = useState(false);
  const [role, setRole] = useState('');
  const columns = useColumns();
  const navigate = useNavigate();
  const { company } = useAuthContext();

  let filterParameter = `role=${role}__`;

  const onFilterRole = (r) => {
    setRole(r || '');
  };

  useEffect(() => {
    if (loaded === true) {
      filterParameter = `role=${role}__`;

      navigate({
        pathname: '/users',
        search: `?f=${filterParameter}`
      });
    } else {
      setLoaded(true);
    }
  }, [role, loaded]);

  return (
    <ListResource
      extraQuery={`company_Id=${company}`}
      resourceName="users"
      tradKey="users"
      columns={columns}
      withExtraFilters={<SelectByRole onChange={onFilterRole} />}
      setLoaded={() => setLoaded()}
      loaded={loaded}
      isArchived
      headers={headers}
    />
  );
};
