/**
 * Checks if all mandatory documents are present in the given files configuration.
 * @function
 * @param {string[]} mandatoryDocuments - An array of mandatory document keys.
 * @param {Object[]} filesConfiguration - An array of file configurations.
 * @param {string} filesConfiguration[].fileKey - The key representing a file in the configuration.
 * @returns {boolean} Returns true if all mandatory documents are present, otherwise false.
 */

export const checkMandatoryDocuments = (
  mandatoryDocuments,
  filesConfiguration
) => {
  if (mandatoryDocuments.length === 0) {
    return true;
  }
  const mandatoryDocumentsArePresent = mandatoryDocuments.every(
    (mandatoryDocument) =>
      filesConfiguration.some(
        (fileConfig) => fileConfig.fileKey === mandatoryDocument
      )
  );

  return mandatoryDocumentsArePresent;
};
