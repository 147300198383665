import { Route, Routes } from 'react-router-dom';
import { ListLightVehicles } from './ListLightVehicles';
import { LightVehiclesCreateUpdate } from './LightVehiclesCreateUpdate';
import { LightVehiclesShow } from './LightVehiclesShow';
import { Exception } from '../../../../components';

export const LightVehiclesRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<LightVehiclesCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<LightVehiclesCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<LightVehiclesShow />} />
    <Route index element={<ListLightVehicles />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
