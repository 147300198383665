import { Route, Routes } from 'react-router-dom';
import { ListProperty } from './ListProperty';
import { PropertyCreateUpdate } from './PropertyCreateUpdate';
import { PropertyShow } from './PropertyShow';
import { Exception } from '../../../components';

/**
 * A router component for handling property-related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the PropertyRouter.
 */
export const PropertyRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<PropertyCreateUpdate purpose="create" />}
    />
    <Route path="edit/:id" element={<PropertyCreateUpdate purpose="edit" />} />
    <Route path="show/:id" element={<PropertyShow />} />
    <Route index element={<ListProperty />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
