export const permissionVerification = (permissions, location) => {
  const CheckPath = (perm) => {
    const path = perm.split('/');
    const locationPath = location.split('/');

    return path.some(
      (loc, index) => loc === locationPath[index] && index === path.length - 1
    );
  };

  return permissions?.notPermittedRoutes?.some(CheckPath) || false;
};
