import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Col, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Step } = Steps;

/**
 * A component for displaying a stepper for construction insurance status.
 *
 * This component allows users to navigate through different status steps for construction insurance.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.constructionInsurance - The construction insurance object with status information.
 * @param {string} props.id - The ID of the construction insurance.
 *
 * @returns {JSX.Element} The rendered `StepperConstructionInsurance` component.
 */

export const StepperConstructionInsurance = ({ constructionInsurance, id }) => {
  const status = constructionInsurance?.status;
  const [currentStatus, setCurrentStatus] = useState(status);
  const [saveStatus, setSaveStatus] = useState(false);
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const message = useErrorMessage();
  let stepIndexToStatus = [];

  if (status === 'AMENDMENT_REQUEST') {
    stepIndexToStatus = [
      'SUBSCRIPTION',
      'AWAITING_CUSTOMER_RESPONSE',
      'AWAITING_COMPANY_RESPONSE',
      'AWAIITING_CONTRACT_START',
      'RUNNING_CONTRACT',
      'AMENDMENT_REQUEST'
    ];
  } else {
    stepIndexToStatus = [
      'SUBSCRIPTION',
      'AWAITING_CUSTOMER_RESPONSE',
      'AWAITING_COMPANY_RESPONSE',
      'AWAIITING_CONTRACT_START',
      'RUNNING_CONTRACT',
      'RECEPTION'
    ];
  }
  // const stepIndexToStatus = [
  //   'SUBSCRIPTION',
  //   'AWAITING_CUSTOMER_RESPONSE',
  //   'AWAITING_COMPANY_RESPONSE',
  //   'AWAIITING_CONTRACT_START',
  //   'RUNNING_CONTRACT',
  //   'AMENDMENT_REQUEST',
  //   'RECEPTION'
  // ];

  const updateStatus = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/constructions/${id}`,
        body: JSON.stringify({
          values: JSON.stringify({ status: currentStatus })
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const currentStep = stepIndexToStatus.indexOf(currentStatus);

  const handleStepClick = (stepIndex) => {
    const newStatus = stepIndexToStatus[stepIndex];
    setSaveStatus(true);
    setCurrentStatus(newStatus);
  };
  useEffect(() => {
    if (saveStatus) {
      updateStatus();
      setSaveStatus(false);
    }
  }, [currentStatus]);
  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  return (
    <Col span={24}>
      {status && (
        <Steps size="small" current={currentStep}>
          {stepIndexToStatus.map((indexStatus, index) => (
            <Step
              key={indexStatus}
              title={t(`constructions.form.${indexStatus}`)}
              onClick={() => handleStepClick(index)}
            />
          ))}
        </Steps>
      )}
    </Col>
  );
};

StepperConstructionInsurance.propTypes = {
  constructionInsurance: PropTypes.shape({
    status: PropTypes.string
  }),
  id: PropTypes.string
};

StepperConstructionInsurance.defaultProps = {
  constructionInsurance: null,
  id: null
};
