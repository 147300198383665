import React, { useState, useEffect } from 'react';
import { notification, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

export const ButtonToDownload = ({ record, type }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);

  const downloadDocument = async ({
    _id,
    metadata: { originalName },
    contentType
  }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (originalName.includes('.pdf')) {
        a.target = '_blank';
      } else a.download = originalName;
      a.click();
      notification.success({ message: t('export.messages.documentdown') });
    } catch (e) {
      message(e);
    }
  };

  const getDocument = async () => {
    try {
      const result = record.documents.find(
        (document) => document.fileKey === type
      );
      await downloadDocument(result.file);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    const checkAndShowButton = () => {
      try {
        if (record.documents) {
          const result = record.documents.find(
            (document) => document.fileKey === type
          );
          setShowButton(!!result);
        } else setShowButton(false);
      } catch (e) {
        message(e);
      }
    };

    checkAndShowButton();
  }, [record, type]);

  const onClick = async () => {
    await getDocument(type);
  };

  return showButton ? (
    <Button onClick={onClick} icon={<DownloadOutlined />}>
      {t('files.create.download')}
    </Button>
  ) : null;
};

ButtonToDownload.propTypes = {
  record: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        fileKey: PropTypes.string.isRequired,
        file: PropTypes.shape({
          _id: PropTypes.string.isRequired,
          metadata: PropTypes.shape({
            originalName: PropTypes.string.isRequired
          }).isRequired,
          contentType: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired
  }).isRequired,
  type: PropTypes.string.isRequired
};
