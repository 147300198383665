import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, Tag, DatePicker, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;

export const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, company } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['date_of_birth'],
      input: <DatePicker />
    },
    {
      name: ['role'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.roles || [])
            .filter((role) => {
              if (company === '64e480fb7b38bc884859ad2f') {
                return role.includes('admins');
              }
              return role.includes('users');
            })
            .map((role) => (
              <Option key={role} value={role}>
                <Tag color={userRoles[role.split(':')[1]]}>
                  {t(`users.tags.${role.split(':')[1]}`)}
                </Tag>
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      label: 'address.country',
      name: ['address', 'country']
    },
    {
      label: ['avatar'],
      name: ['avatar'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    },
    {
      label: ['send_email'],
      name: ['send_email'],
      input: (
        <Switch
          checkedChildren={t('users.form.yes')}
          unCheckedChildren={t('users.form.no')}
        />
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
