/**
 * Search for nodes matching the search term in an array of nodes.
 *
 * @function
 * @param {string} keySearched - The search term.
 * @param {Array} nodes - The array of nodes to search in.
 * @returns {Array} - An array of keys of nodes that match the search term.
 */
export const onSearch = (keySearched, nodes) => {
  if (!nodes) return [];

  const newExpandedKeys = nodes.reduce((acc, node) => {
    let title = node?.title?.toLowerCase();
    title = title?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
    if (title?.indexOf(keySearched) > -1) {
      acc.push(node.key);
    }
    if (node.children) {
      acc.push(...onSearch(keySearched, node.children));
    }
    return acc;
  }, []);

  return newExpandedKeys;
};

/**
 * Handle the search input and update the search term state.
 *
 * @function
 * @param {string} value - The search term entered by the user.
 * @param {Function} setKeySearched - A function to update the search term state.
 * @returns {Array|null} - An empty array if the search term is empty, otherwise null.
 */
export const handleSearch = (value, setKeySearched) => {
  if (!value) {
    setKeySearched('');
    return [];
  }

  const keyword = value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  setKeySearched(keyword);
  return null;
};

/**
 * Search for nodes matching the search term in an array of nodes for the second user.
 *
 * @function
 * @param {string} keySearched - The search term.
 * @param {Array} nodes - The array of nodes to search in.
 * @returns {Array} - An array of keys of nodes that match the search term.
 */
export const onSearchUserTwo = (keySearched, nodes) => {
  if (!nodes) return [];

  const newExpandedKeys = nodes.reduce((acc, node) => {
    let title = node.title.toLowerCase();
    title = title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    if (title.indexOf(keySearched) > -1) {
      acc.push(node.key);
    }
    if (node.children) {
      acc.push(...onSearch(keySearched, node.children));
    }
    return acc;
  }, []);
  return newExpandedKeys;
};

/**
 * Handle the search input for the second user and update the search term state.
 *
 * @function
 * @param {string} value - The search term entered by the user.
 * @param {Function} setKeySearched - A function to update the search term state.
 * @returns {Array|null} - An empty array if the search term is empty, otherwise null.
 */
export const handleSearchUserTwo = (value, setKeySearched) => {
  if (!value) {
    setKeySearched('');
    return [];
  }

  const keyword = value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  setKeySearched(keyword);
  return null;
};
