/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @hook
 */

const useFields = () => {
  const fieldsEntity = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['kbis_entitiy'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    },
    {
      name: ['ics_entitiy'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];

  return {
    fieldsEntity
  };
};
export default useFields;
