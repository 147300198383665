import { Route, Routes } from 'react-router-dom';
import { ListOtherVehicles } from './ListOtherVehicles';
import { OtherVehiclesCreateUpdate } from './OtherVehiclesCreateUpdate';
import { OtherVehiclesShow } from './OtherVehiclesShow';
import { Exception } from '../../../../components';

export const OtherVehiclesRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<OtherVehiclesCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<OtherVehiclesCreateUpdate purpose="create" />}
    />
    <Route path="show/:id" element={<OtherVehiclesShow />} />
    <Route index element={<ListOtherVehicles />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
