import { Route, Routes } from 'react-router-dom';
import { ListLightVehicles } from './ListLightVehicles';
import { LightVehiclesCreateUpdate } from './LightVehiclesCreateUpdate';
import { LightVehicleShow } from './LightVehicleShow';
import { Exception } from '../../../../components';
import { LightVehiclesContextProvider } from './LightVehiclesContext';

export const LightVehiclesRouter = () => (
  <LightVehiclesContextProvider>
    <Routes>
      <Route
        path="create"
        element={<LightVehiclesCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<LightVehiclesCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<LightVehicleShow />} />
      <Route index element={<ListLightVehicles />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </LightVehiclesContextProvider>
);
