import { Route, Routes } from 'react-router-dom';
import { ListHeavyVehicles } from './ListHeavyVehicles';
import { HeavyVehiclesCreateUpdate } from './HeavyVehiclesCreateUpdate';
import { HeavyVehicleShow } from './HeavyVehicleShow';
import { Exception } from '../../../../components';
import { HeavyVehiclesContextProvider } from './HeavyVehiclesContext';

export const HeavyVehiclesRouter = () => (
  <HeavyVehiclesContextProvider>
    <Routes>
      <Route
        path="create"
        element={<HeavyVehiclesCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<HeavyVehiclesCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<HeavyVehicleShow />} />
      <Route index element={<ListHeavyVehicles />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </HeavyVehiclesContextProvider>
);
