import PropTypes from 'prop-types';
import moment from 'moment';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../contexts/AuthContext';

export const CreateUpdateUser = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();
  const { user, setUser, company } = useAuthContext();
  const company_Id = { company_Id: company };
  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...company_Id
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        return {
          ...data
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
      withFilesManager={false}
      populate="?populate=documents,documents.file"
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};
