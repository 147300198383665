import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Col,
  Avatar,
  notification
} from 'antd';
import { MailReply } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { createFileFromJSON } from '../../components/CreateUpdateContainerV3/utils/createFileFromJSON';

/**
 * Displays user details and provides options to edit or delete the user.
 *
 * @component
 * @returns {JSX.Element} The user details display component.
 */

export const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [showEmailSent, setShowEmailSent] = useState(false);
  const listContent = useListContent(user);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=documents.file`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        if (user && user.documents && user.documents.length > 0) {
          const fileWithUrl = await createFileFromJSON(
            user.documents[0],
            dispatchAPI,
            message
          );
          setAvatarUrl(fileWithUrl.url);
        }
      } catch (e) {
        message(e);
      }
    };

    fetchAvatar();
  }, [user, dispatchAPI, message]);

  useEffect(() => {
    if (user?.verified_email === false) {
      setShowEmailSent(true);
    } else {
      setShowEmailSent(false);
    }
  }, [user]);

  const sendEmail = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/users/sendEmail/${id}`
      });
      notification.success({ message: t('users.sendEmail_success') });
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            {showEmailSent && (
              <Button type="primary" onClick={() => sendEmail()}>
                <MailReply />
                {`${t('buttons.users_sendEmail')} `}
              </Button>
            )}
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteUser}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Col xs={24} md={8} lg={6} xl={4} xxl={2}>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={0}
              avatar={{ size: 80 }}
              active
            >
              <Avatar size={80} src={avatarUrl} alt="Avatar" />
            </Skeleton>
          </Col>
          <Col xs={24} md={16} lg={18} xl={20} xxl={22}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList data={listContent} translate layout="vertical" />
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
