import { Route, Routes } from 'react-router-dom';
import { OthersConstructionCreateUpdate } from './OthersConstructionCreateUpdate';
import { OthersConstructionShow } from './OthersConstructionShow';
import { ListOthersConstruction } from './ListOthersConstruction';
import { Exception } from '../../../../components';

/**
 * `OthersConstructionRouter` is a component responsible for defining the routing structure.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the Ad Valorem router.
 */

export const OthersConstructionRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<OthersConstructionCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<OthersConstructionCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<OthersConstructionShow />} />
    <Route index element={<ListOthersConstruction />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
