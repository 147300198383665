import { Route, Routes } from 'react-router-dom';
import { ListCivilLiabilityExecutive } from './ListCivilLiabilityExecutive';
import { CivilLiabilityExecutiveCreateUpdate } from './CivilLiabilityExecutiveCreateUpdate';
import { CivilLiabilityExecutiveShow } from './CivilLiabilityExecutiveShow';
import { Exception } from '../../../../components';

/**
 * `CivilLiabilityExecutiveRouter` is a component responsible for defining the routing structure.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the Ad Valorem router.
 */

export const CivilLiabilityExecutiveRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<CivilLiabilityExecutiveCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<CivilLiabilityExecutiveCreateUpdate purpose="create" />}
    />
    <Route path="show/:id" element={<CivilLiabilityExecutiveShow />} />
    <Route index element={<ListCivilLiabilityExecutive />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
