import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('claims.form.expert_first_name'),
      dataIndex: 'expert_first_name',
      key: 'first_name'
    },
    {
      title: t('claims.form.expert_last_name'),
      dataIndex: 'expert_last_name',
      key: 'last_name'
    },
    {
      title: t('claims.form.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('claims.form.phone_number'),
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (phone_number) =>
        phone_number?.country_code &&
        phone_number?.number && (
          <>
            {phone_number?.country_code} {phone_number?.number}
          </>
        )
    }
  ];
};
