import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../../assets/images/logo-white.svg';
import LogoCollapsed from '../../assets/images/logo-white-small.svg';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/handleResize';
import { AdminNavMenu } from './AdminNavMenu';
import AdminCustomerSelector from '../AdminCustomerSelector';
import { UnitAppContextProvider } from '../../contexts/UnitAppContext';
import { UpdateAlert } from '../UpdateAlert/UpdateAlert';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  height: 100vh;
  z-index: 10;
  overflow: hidden;
  position: fixed;
  left: 0;
  padding-top: 12px;
`;

const LogoDiv = styled.div`
  position: relative;
  height: 56px;
  padding-left: 0;
  overflow: hidden;
  line-height: 56px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 48px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  margin: 56px 0 0 0;
  min-height: calc(100vh - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: right;
  color: var(--textColor);
  background: var(--bodyBackground);

  a {
    color: var(--primaryColor);
  }
`;

export const AdminLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <UnitAppContextProvider>
      <StyledLayout as={Layout}>
        <UpdateAlert />
        <StyledSider
          as={Sider}
          width={256}
          breakpoint="lg"
          trigger={null}
          collapsible
          collapsedWidth={collapseWidth}
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <LogoDiv>
            <Link to="/">
              <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
            </Link>
          </LogoDiv>
          <AdminNavMenu setCollapsed={setCollapsed} width={width} />
          <AdminCustomerSelector />
        </StyledSider>
        <Layout>
          <HeaderLayout
            collapsed={collapsed}
            collapseSider={setCollapsed}
            collapseWidth={collapseWidth}
          />
          <StyledContent as={Content} id="mainContent">
            <Outlet />
            <StyledFooter as={Footer}>
              <Link to="/legal-notice">{t('global.legal_notice')} • </Link>
              {`${t('global.title')} ©${moment().year()} powered by `}
              <a href="https://strateg.in">Strateg.in</a>
            </StyledFooter>
          </StyledContent>
        </Layout>
      </StyledLayout>
    </UnitAppContextProvider>
  );
};
