import PropTypes from 'prop-types';
import moment from 'moment';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

export const CreateUpdateCompany = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        creation_date: data.creation_date && moment(data.creation_date)
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="companies"
      resource="companies"
      config={config}
    />
  );
};

CreateUpdateCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};
