import { Route, Routes } from 'react-router-dom';
import { ListHeavyVehicles } from './ListHeavyVehicles';
import { HeavyVehiclesCreateUpdate } from './HeavyVehiclesCreateUpdate';
import { HeavyVehiclesShow } from './HeavyVehiclesShow';
import { Exception } from '../../../../components';

export const HeavyVehiclesRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<HeavyVehiclesCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<HeavyVehiclesCreateUpdate purpose="create" />}
    />
    <Route path="show/:id" element={<HeavyVehiclesShow />} />
    <Route index element={<ListHeavyVehicles />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
