import moment from 'moment';

/**
 * Hook that processes and formats various pieces of data for vehicle-related content.
 * It takes in raw data and outputs a list of labels with associated content for display.
 * This hook is useful for uniformly handling and displaying vehicle-related data across components.
 *
 * @hook
 * @param {Object} data - The raw data to be processed.
 * @param {Object} data.contract - Details of the vehicle contract.
 * @param {string} data.created_at - Date when the record was created.
 * @param {Object} data.entity - Entity details associated with the vehicle.
 * @param {Object} data.site - Site details where the vehicle is located.
 * @param {Object} data.customer_manager - Manager details for the customer.
 * @param {Object} data.unit_manager - Manager details for the unit.
 * @param {string} data.collaborator_last_name - Last name of the collaborator.
 * @param {string} data.collaborator_first_name - First name of the collaborator.
 * @param {string} data.vehicle_registration_number - Registration number of the vehicle.
 * @param {string} data.start_of_the_mission - Start date of the mission.
 * @param {string} data.planned_end_of_the_mission - Planned end date of the mission.
 * @param {string} data.departure_ - Various parts of the departure address (street number, street name, additional address, zip code, city, country).
 * @param {string} data.arrival_ - Various parts of the arrival address (street number, street name, additional address, zip code, city, country).
 *
 * @returns {Array<Object>} labels - An array of label objects, each containing:
 * - `label`: The i18n key for the label.
 * - `span`: The span value (likely for grid layout).
 * - `content`: The content associated with the label, properly formatted.
 *
 * const vehicleLabels = useListContent(vehicleData);
 */

export const useListContent = (data = {}) => {
  const {
    contract,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    collaborator_last_name,
    collaborator_first_name,
    vehicle_registration_number,
    start_of_the_mission,
    planned_end_of_the_mission,
    departure_street_number,
    departure_street_name,
    departure_additional_address,
    departure_zip_code,
    departure_city,
    departure_country,
    arrival_street_number,
    arrival_street_name,
    arrival_additional_address,
    arrival_zip_code,
    arrival_city,
    arrival_country
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const addressPartsdeparture = [
    departure_street_number,
    departure_street_name,
    departure_additional_address,
    departure_zip_code,
    departure_city,
    departure_country
  ];
  const departureAddress = addressPartsdeparture
    .filter((part) => part)
    .join(' ');

  const addressPartsArrival = [
    arrival_street_number,
    arrival_street_name,
    arrival_additional_address,
    arrival_zip_code,
    arrival_city,
    arrival_country
  ];
  const arrivalAddress = addressPartsArrival.filter((part) => part).join(' ');

  const labels = [
    {
      label: 'vehicles.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'vehicles.form.contract',
      span: 1,
      content: entity?.company?.name || '-'
    },
    {
      label: 'vehicles.form.date_added',
      span: 1,
      content: (created_at && moment(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'vehicles.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'vehicles.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'vehicles.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'vehicles.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'vehicles.form.collaborator_last_name',
      span: 1,
      content: (collaborator_last_name && collaborator_last_name) || '-'
    },
    {
      label: 'vehicles.form.collaborator_first_name',
      span: 1,
      content: (collaborator_first_name && collaborator_first_name) || '-'
    },
    {
      label: 'vehicles.form.vehicle_registration_number',
      span: 1,
      content:
        (vehicle_registration_number && vehicle_registration_number) || '-'
    },
    {
      label: 'vehicles.form.start_of_the_mission',
      span: 1,
      content:
        (start_of_the_mission &&
          moment(start_of_the_mission).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.planned_end_of_the_mission',
      span: 1,
      content:
        (planned_end_of_the_mission &&
          moment(planned_end_of_the_mission).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.departure_location',
      span: 3,
      content: departureAddress || '-'
    },
    {
      label: 'vehicles.form.arrival_location',
      span: 3,
      content: arrivalAddress || '-'
    }
  ];

  return labels;
};
