import React, { useState, createContext } from 'react';

/**
 * Provides a context for managing contacts-related state.
 * It holds the state related to refreshing contacts and the ID of the driver being edited.
 *
 * @component
 *
 * @param {Object} props - Props for `ContactsContextProvider`
 * @param {React.ReactNode} props.children - Child components that will have access to the context
 *
 * @returns {JSX.Element} The `ContactsContext.Provider` wrapping children components.
 */

export const ContactsContext = createContext();

export const ContactsContextProvider = ({ children }) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [idWithoutParams, setIdWithoutParams] = useState(null);

  return (
    <ContactsContext.Provider
      value={{
        idWithoutParams,
        setIdWithoutParams,
        forceRefresh,
        setForceRefresh
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
