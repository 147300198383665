import PropTypes from 'prop-types';
import { Tree } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayTreeNodes } from './DisplayTreeNodes';
import { useOrganizationData } from '../Hooks/useOrganizationData';
import { useTreeExpansion } from '../Hooks/useTreeExpansion';

const { TreeNode } = Tree;

const TreeStructure = ({ keySearched }) => {
  const { renderTreeNodes } = DisplayTreeNodes();
  const { t } = useTranslation();
  const { dataTree } = useOrganizationData();
  const { expandedKeys, isSearchBarEmpty, onExpand } = useTreeExpansion(
    keySearched,
    dataTree
  );

  return (
    <Tree
      style={{ marginTop: 16 }}
      expandedKeys={expandedKeys}
      autoExpandParent={!isSearchBarEmpty}
      onExpand={onExpand}
    >
      {dataTree && (
        <TreeNode
          key={t('companies.tree.programs')}
          title={t('companies.tree.programs')}
        >
          {renderTreeNodes(dataTree)}
        </TreeNode>
      )}
    </Tree>
  );
};

export default TreeStructure;

TreeStructure.propTypes = {
  keySearched: PropTypes.string.isRequired
};
