import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerModified';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * `CreateUpdateContacts` is a React component responsible for creating or updating contacts.
 * It relies on various contexts for fetching data and manages the fields, configurations,
 * and behaviors for creating/updating.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.purpose - The purpose of the component, usually to determine if it's for creating or updating.
 *
 * @returns {JSX.Element} A rendered CreateUpdateContainer component with the specific configurations for contacts.
 */

export const CreateUpdateContacts = ({ purpose }) => {
  const { fields } = useFields();
  const { company } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data[0]
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="contacts"
      resource="contacts"
      config={config}
      withFilesManager={false}
    />
  );
};

CreateUpdateContacts.propTypes = {
  purpose: PropTypes.string.isRequired
};
