import { useTranslation } from 'react-i18next';

export const useListItemsStep = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t('claims.form.received_declaration')
    },
    {
      title: t('claims.form.informations_waiting')
    },
    {
      title: t('claims.form.expertise_in_progress')
    },
    {
      title: t('claims.form.understudy_compensation')
    },
    {
      title: t('claims.form.appeal')
    },
    {
      title: t('claims.form.awaiting_closing')
    }
  ];
  return { items };
};

export const useListBeforeValidation = () => {
  const { t } = useTranslation();
  const itemsBeforeValidation = [
    {
      title: t('claims.form.received_declaration')
    },
    {
      title: t('claims.form.informations_waiting')
    }
  ];
  return { itemsBeforeValidation };
};
