import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const UnitAppContext = createContext({});

export const UnitAppContextProvider = ({ children }) => (
  <UnitAppContext.Provider value={{}}>{children}</UnitAppContext.Provider>
);
UnitAppContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
export default () => useContext(UnitAppContext);
