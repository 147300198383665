import { Route, Routes } from 'react-router-dom';
import { ListProperty } from './ListProperty';
import { PropertyCreateUpdate } from './PropertyCreateUpdate';
import { PropertyShow } from './PropertyShow';
import { Exception } from '../../../components';
import { PropertyContextProvider } from './PropertyContext';

export const PropertyRouter = () => (
  <PropertyContextProvider>
    <Routes>
      <Route
        path="create"
        element={<PropertyCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<PropertyCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<PropertyShow />} />
      <Route index element={<ListProperty />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </PropertyContextProvider>
);
