import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * `DescriptionList` is a component that renders a list of descriptions with configurable settings.
 *
 * @component
 * @prop {string} [title=null] - Title for the description list.
 * @prop {Array} data - Array of data objects containing label and content for each item.
 * @prop {boolean} [skipEmpty=false] - A flag to determine if items with empty content should be skipped.
 * @prop {boolean} [translate=false] - A flag to determine if the labels need translation.
 * @prop {string} [layout='horizontal'] - Layout of the description list. Could be 'horizontal' or 'vertical'.
 * @prop {number} [column=3] - Number of columns for the description list.
 * @returns {ReactElement} Rendered `DescriptionList` component.
 */

export const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  layout,
  column,
  extra
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Descriptions title={title} layout={layout} column={column} extra={extra}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) && (
              <Descriptions.Item
                label={
                  translate ? t(item.label, { index: item.index }) : item.label
                }
                span={item.span || 1}
                key={item.label}
              >
                <Typography style={{ fontWeight: 700 }}>
                  {item.content || '-'}
                </Typography>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool,
  layout: PropTypes.string,
  column: PropTypes.number,
  extra: PropTypes.node
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  layout: 'horizontal',
  column: 3,
  extra: null
};
