import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Tag } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;

/**
 * `SelectByRole` is a custom component for selecting users by their roles.
 *
 * @component
 * @param {Function} onChange - A callback function to be called when the selection changes.
 * @returns {JSX.Element} The JSX element for the role selection component.
 */

export const SelectByRole = ({ onChange }) => {
  const { t } = useTranslation();
  const [enums, setEnums] = useState([]);
  const { dispatchAPI, company, companyName } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      let filteredRoles = [];
      if (
        data?.roles &&
        (company === '64e480fb7b38bc884859ad2f' || companyName === 'UNIT')
      ) {
        filteredRoles = data.roles.filter(
          (role) =>
            role !== 'developers:DEVELOPER' &&
            role !== 'guests:GUEST' &&
            role !== 'users:USER' &&
            role !== 'users:SUPER-USER'
        );
        setEnums({ ...data, roles: filteredRoles });
      } else {
        filteredRoles = data.roles.filter(
          (role) =>
            role !== 'developers:DEVELOPER' &&
            role !== 'guests:GUEST' &&
            role !== 'admins:ADMIN' &&
            role !== 'admins:SUPER-ADMIN'
        );
        setEnums({ ...data, roles: filteredRoles });
      }
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, [getEnums]);

  return (
    <Select
      allowClear
      placeholder={t('users.filter.role')}
      showSearch
      onChange={onChange}
      filterOption={(input, option) =>
        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
      }
    >
      {enums &&
        enums?.roles?.map((e) => (
          <Option key={e} value={e}>
            <Tag color={userRoles[e.split(':')[1]]}>
              {t(`users.tags.${e.split(':')[1]}`, {
                defaultValue: e.split(':')[1]
              })}
            </Tag>
          </Option>
        ))}
    </Select>
  );
};

SelectByRole.propTypes = {
  onChange: PropTypes.func.isRequired
};
