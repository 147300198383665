import PropTypes from 'prop-types';
import { Row } from 'antd';
import { useFields } from './expertFields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerModified';
import { useAuthContext } from '../../../contexts/AuthContext';

export const CreateUpdateExpert = ({
  purpose,
  programmeType,
  idWithoutParams,
  setIdWithoutParams
}) => {
  const { fieldsExpert } = useFields();
  const { company } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company,
        programme_types: programmeType
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  return (
    <Row>
      {purpose && (
        <CreateUpdateContainer
          fields={fieldsExpert}
          purpose={purpose}
          resource="claims"
          baseUrl="experts"
          config={config}
          withFilesManager={false}
          WithPageHeaderCustom={false}
          idWithoutParams={idWithoutParams}
          setIdWithoutParams={setIdWithoutParams}
        />
      )}
    </Row>
  );
};

CreateUpdateExpert.propTypes = {
  purpose: PropTypes.string,
  programmeType: PropTypes.string,
  idWithoutParams: PropTypes.string,
  setIdWithoutParams: PropTypes.func
};

CreateUpdateExpert.defaultProps = {
  purpose: null,
  programmeType: null,
  idWithoutParams: null,
  setIdWithoutParams: null
};
