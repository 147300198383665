import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Select, InputNumber } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { MultipleFormFieldsGeneration } from '../../../components/MultipleFormFieldsGeneration';

const { Option } = Select;

/**
 * Custom hook `useFieldsDrivers` provides form field definitions and utilities for driver-related forms.
 * This hook also fetches enums for the drivers from the API, which are used to populate select input options.
 *
 * @hook
 * @returns {Object} Contains:
 *  - {Array} driversFields: An array of field definitions to use in antd forms.
 *  - {boolean} isFieldsLoading: A state indicating if the enum fields are currently being loaded.
 */

export const useFieldsDrivers = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/drivers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();

    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const licenceFields = [
    {
      name: ['licence_selection'],
      rules: [{ required: false }],
      input: (
        <Select
          placeholder={t('drivers.form.licence_selection')}
          showSearch
          filterOption={(input, option) =>
            (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {enums?.licence_selection?.map((e) => (
            <Option key={e} value={e}>
              {e}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['date_obtained'],
      rules: [{ required: false }],
      input: <DatePicker placeholder={t('drivers.form.date_obtained')} />
    }
  ];

  const driversFields = [
    {
      name: ['is_principal'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />,
      startWithDivider: {
        title: t('drivers.form.main_informations')
      }
    },
    {
      name: ['last_name'],
      rules: [{ required: false }]
    },
    {
      name: ['first_name'],
      rules: [{ required: false }]
    },
    {
      name: ['coefficient'],
      rules: [{ required: false }],
      input: <InputNumber />,
      endWithDivider: {
        title: t('drivers.form.driving_licence_withdrawal_reasons')
      }
    },
    {
      name: ['drunk_and_drive'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['hit_and_run'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['refusal_to_comply'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['driving_licence_withdrawal'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['narcotics'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['insurance_cancelled'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />,
      endWithDivider: {
        title: t('drivers.form.driving_licences')
      }
    },
    {
      noLabel: true,
      name: ['driving_licences'],
      rules: [{ required: false }],
      input: (
        <MultipleFormFieldsGeneration
          fields={licenceFields}
          dbKey="driving_licences"
          tradKey="drivers"
        />
      )
    }
  ];
  return { driversFields, isFieldsLoading, licenceFields };
};
