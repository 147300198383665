import { useEffect, useState } from 'react';
import { onSearch } from '../utils/searchUtils';

export const useTreeExpansion = (keySearched, dataTree) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [isSearchBarEmpty, setIsSearchBarEmpty] = useState(true);

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  useEffect(() => {
    const newExpandedKeys = onSearch(keySearched, dataTree);
    setExpandedKeys(newExpandedKeys);
    setIsSearchBarEmpty(keySearched === '');
  }, [keySearched]);

  return { expandedKeys, autoExpandParent, isSearchBarEmpty, onExpand };
};
