import { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/logo-white.svg';
import { UpdateAlert } from '../../components/UpdateAlert/UpdateAlert';

export const Login = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <>
      <UpdateAlert />
      <Layout className="login-layout">
        <Row>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 7, offset: 1 }}
            xxl={{ span: 6, offset: 2 }}
            className="login-form-col"
          >
            <img
              style={{
                width: 240,
                marginBottom: 56
              }}
              alt="Logo"
              src={Logo}
            />
            <Title className="login-title">
              {t(`login.title.${currentForm}`)}
            </Title>
            {forms[currentForm]}
          </Col>
        </Row>
      </Layout>
    </>
  );
};
