import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Item } = Form;
const { Option } = Select;

export const SelectUnitStaff = ({ dbKey }) => {
  const [unitStaff, SetUnitStaff] = useState([]);
  const { dispatchAPI } = useContext(AuthContext);
  const { message } = useErrorMessage();
  let role;
  if (dbKey === 'unit_manager') {
    role = { role: 'admins:SUPER-ADMIN' };
  } else {
    role = { role: 'admins:ADMIN' };
  }

  const getUnitStaff = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/users/unit`,
        body: role
      });
      SetUnitStaff(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getUnitStaff();
    })();
  }, [getUnitStaff]);

  return (
    <Item name={[`${dbKey}`]}>
      <Select>
        {unitStaff
          ? unitStaff.map((unitMember) => (
              <Option key={unitMember._id} value={unitMember._id}>
                {unitMember.first_name}&nbsp;{unitMember.last_name}
              </Option>
            ))
          : null}
      </Select>
    </Item>
  );
};

SelectUnitStaff.propTypes = {
  dbKey: PropTypes.string.isRequired
};
