import { Route, Routes } from 'react-router-dom';
import { CreateUpdateContacts } from './CreateUpdateContacts';
import { ShowContacts } from './ShowContacts';
import { Exception } from '../../components';
import { ContactsContextProvider } from './ContactsContext';

/**
 * Provides a set of routes for contacts-related pages.
 * The `ContactsContextProvider` wraps all routes to ensure all nested components
 * have access to the contacts context.
 *
 * @component
 * @returns {JSX.Element} A set of routes wrapped within `ContactsContextProvider`.
 */

export const ContactsRouter = () => (
  <ContactsContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateContacts purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateContacts purpose="edit" />}
      />
      <Route index element={<ShowContacts />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ContactsContextProvider>
);
