import { Route, Routes } from 'react-router-dom';
import { ConstructionInsuranceCreateUpdate } from './ConstructionInsuranceCreateUpdate';
import { ConstructionInsuranceShow } from './ConstructionInsuranceShow';
import { ListConstructionInsurance } from './ListConstructionInsurance';
import { Exception } from '../../../../components';

/**
 * `ConstructionInsuranceRouter` is a component responsible for defining the routing.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the Ad Valorem router.
 */

export const ConstructionInsuranceRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<ConstructionInsuranceCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<ConstructionInsuranceCreateUpdate purpose="create" />}
    />
    <Route path="show/:id" element={<ConstructionInsuranceShow />} />
    <Route index element={<ListConstructionInsurance />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
