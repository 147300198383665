import { useContext, useState, createContext } from 'react';

export const CompanyContext = createContext({});

export const CompanyContextProvider = ({ children }) => {
  const [forceRefresh, setForceRefresh] = useState(false);

  return (
    <CompanyContext.Provider
      value={{
        forceRefresh,
        setForceRefresh
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => {
  const context = useContext(CompanyContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
