import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useListContentClaim = (data = {}) => {
  const { t } = useTranslation();
  const {
    sinister_date,
    opening_date,
    customer_manager,
    unit_manager,
    lifted_reservations,
    entity,
    site,
    insurance_company_reference,
    street_number,
    additional,
    street,
    zip_code,
    city,
    country
  } = data;

  const claimAdressParts = [
    street_number,
    street,
    additional,
    zip_code,
    city,
    country
  ];
  const claimAdress = claimAdressParts.filter((part) => part).join(' ');

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'claims.form.sinister_date',
      span: 1,
      content:
        (sinister_date && moment(sinister_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.opening_date',
      span: 2,
      content:
        (opening_date && moment(opening_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'claims.form.unit_manager',
      span: 2,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'claims.form.insurance_company_reference',
      span: 3,
      content: insurance_company_reference
    },
    {
      label: 'claims.form.entity',
      span: 1,
      content: entity?.name || '-'
    },
    {
      label: 'claims.form.site',
      span: 1,
      content: site?.name || '-'
    },
    {
      label: 'claims.form.lifted_reservations',
      span: 1,
      content:
        (lifted_reservations && t(`claims.form.${lifted_reservations}`)) || '-'
    },
    {
      label: 'claims.form.claim_address',
      span: 3,
      content: (claimAdress && claimAdress) || '-'
    }
  ];

  return labels;
};
