import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useListContentClaim = (data = {}) => {
  const { t } = useTranslation();
  const {
    sinister_date,
    customer_manager,
    unit_manager,
    insurance_company_reference,
    place_of_occurrence,
    client_reference,
    personal_injury
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'claims.form.sinister_date',
      span: 1,
      content:
        (sinister_date && moment(sinister_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.place_of_occurrence',
      span: 2,
      content: (place_of_occurrence && place_of_occurrence) || '-'
    },
    {
      label: 'claims.form.personal_injury',
      span: 1,
      content: (personal_injury && t(`claims.form.${personal_injury}`)) || '-'
    },
    {
      label: 'claims.form.client_reference',
      span: 2,
      content: (client_reference && client_reference) || '-'
    },
    {
      label: 'claims.form.insurance_company_reference',
      span: 2,
      content:
        (insurance_company_reference && insurance_company_reference) || '-'
    },
    {
      label: 'claims.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'claims.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    }
  ];
  return labels;
};
