import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Flood, TrafficIncident } from '@carbon/icons-react';

/**
 * A React component that represents a claim button.
 * @component ClaimButton
 * @param {object} props - The props object containing id, url, and claimType.
 * @param {string} props.id - The ID for the claim button.
 * @param {string} props.url - The URL for the claim button.
 * @param {string} props.claimType - The type of claim, e.g., "transport," "vehicle," etc.
 * @returns {JSX.Element} - A React component that renders a link button for creating a claim.
 */

const claimTypeToIcon = {
  transport: <TrafficIncident size={20} />,
  vehicle: <TrafficIncident size={20} />,
  civilLiability: <Flood size={20} />,
  construction: <Flood size={20} />,
  golf: <Flood size={20} />,
  property: <Flood size={20} />
};

export const ClaimButton = ({ id, url, claimType }) => {
  const { t } = useTranslation();
  const icon = claimTypeToIcon[claimType] || null;

  return (
    <Link to={`/claims/${url}/create/${id}`}>
      <Button>
        {icon}
        {`${t(`buttons.claims`)} `}
      </Button>
    </Link>
  );
};

ClaimButton.propTypes = {
  id: PropType.string.isRequired,
  url: PropType.string.isRequired,
  claimType: PropType.string.isRequired
};
