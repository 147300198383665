import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Button, Modal, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Paragraph, Text } = Typography;

/**
 * A reusable button component to acknowledge receipt of a claim.
 *
 * @component
 * @param {Function} reception - Callback function to handle the reception of the claim.
 * @param {string} claimReferenceNumber - The claim reference number to be displayed.
 * @returns {JSX.Element} The JSX element representing the acknowledgment receipt button.
 */

export const AcknowledgeReceiptButton = ({
  reception,
  claimReferenceNumber
}) => {
  const [isOpenReception, setIsOpenReception] = useState(false);
  const [doNotChangeStep, setDoNotChangeStep] = useState(true);
  const { t } = useTranslation();

  const handleReception = () => {
    setIsOpenReception(true);
  };

  const closeModal = () => {
    setIsOpenReception(false);
    setDoNotChangeStep(false);
  };

  useEffect(() => {
    if (isOpenReception === false && doNotChangeStep === false) {
      reception();
    }
  }, [isOpenReception]);

  return (
    <>
      <Button icon={<CheckOutlined />} onClick={handleReception}>
        {`${t('claims.form.acknowledge_receipt')} `}
      </Button>
      <Modal
        open={isOpenReception}
        footer={[
          <Button onClick={() => closeModal()}>
            {t('claims.form.ok_button')}
          </Button>
        ]}
        title={t('claims.form.acknowledged_receipt')}
      >
        <Typography>
          <Paragraph>
            <Text>{t('claims.form.claim_number')}</Text>
            <Text strong>{`${claimReferenceNumber}`}</Text>
          </Paragraph>
          <Paragraph>
            {t('claims.form.acknowledged_receipt_mail_sent', {
              claimReferenceNumber
            })}
          </Paragraph>
        </Typography>
      </Modal>
    </>
  );
};

AcknowledgeReceiptButton.propTypes = {
  reception: PropTypes.func.isRequired,
  claimReferenceNumber: PropTypes.string.isRequired
};
