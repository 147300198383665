import { useContext, useState, useEffect } from 'react';
import { ListResource } from '../../components';
import { useActionColumn } from './utils/actionColumn';
import { useColumns } from './utils/columnsSubscriptions';
import { useAuthContext } from '../../contexts/AuthContext';
import { SubscriptionContext } from './SubscriptionContext';

/**
 * A component for listing subscriptions.
 * @component
 * @returns {JSX.Element} The JSX element representing the list of subscriptions.
 */
export const ListSubscriptions = () => {
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [accept, setAccept] = useState(true);

  const actionColumn = useActionColumn(edit, accept);
  const { company, permissions, verifPermissions } = useAuthContext();
  const columns = useColumns();
  const { forceRefresh } = useContext(SubscriptionContext);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions('datas/contract', permissions?.notPermittedRoutes)
      );
      setEdit(
        !verifPermissions(
          '/subscriptions/contract/edit',
          permissions?.notPermittedRoutes
        )
      );
      setAccept(
        !verifPermissions(
          '/subscriptions/contract/accept',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  return (
    <ListResource
      extraQuery={`company_id=${company}`}
      tradKey="subscriptions"
      resourceName={`subscriptions/company/${company}`}
      customActionColumn
      columns={[...columns, ...actionColumn]}
      withCreateButton={false}
      forceRefresh={forceRefresh}
      withUploadButton={showData}
    />
  );
};
