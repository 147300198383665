import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

export const SelectEnums = ({ dbKey, onChange }) => {
  const [enums, setEnums] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  let placeholder = '';
  if (
    dbKey === 'light_vehicle_type' ||
    dbKey === 'heavy_vehicle_type' ||
    dbKey === 'other_vehicle_type'
  ) {
    placeholder = t(`vehicles.form.${dbKey}`);
  }
  return (
    <Item name={[`${dbKey}`]}>
      <Select
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={onChange}
      >
        {(enums?.[dbKey] || []).map((v) => (
          <Option key={v} value={v}>
            {placeholder === '' ? v : t(`vehicles.form.enums.${dbKey}.${v}`)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectEnums.propTypes = {
  dbKey: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

SelectEnums.defaultProps = {
  onChange: () => {}
};
